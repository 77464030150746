export const theme = {
  color: {
    primary: {
      main: "rgb(37, 76, 216)",
    },
    yellow: {
      main: "#FECC00",
    },
    green: {
      main: "#42B72A",
    },
    orange: {
      main: "#FF832B",
    },
    danger: {
      main: "#E54C29",
      dark: "#C74224",
    },
    gray: {
      dark: "rgba(21, 21, 21)",
      medium: "rgba(21, 21, 21, 0.8)",
    },
  },
  spacing: {
    XS: ".4rem",
    S: ".8rem",
    M: "1rem",
    L: "1.6rem",
    XL: "2rem",
    XXL: "3rem",
    "3XL": "4rem",
    "4XL": "5rem",
    "5XL": "6rem",
  },
};

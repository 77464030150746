import { useEffect, useState } from "react";
import throttle from "lodash.throttle";

const throttleLimit = 200;

const breakpoints = {
  XXS: 320,
  XS: 540,
  S: 750,
  M: 1024,
  L: 1440,
  XL: 1920,
} as const;

const getStateFromWindowDimensions = () => {
  const height = window.innerHeight || document.documentElement.clientHeight;
  const width = window.innerWidth || document.documentElement.clientWidth;

  return {
    windowDimensions: {
      width,
      height,
    },
    windowIs: {
      XS: width <= breakpoints.XS,
      S: width > breakpoints.XS && width <= breakpoints.S,
      M: width > breakpoints.S && width <= breakpoints.M,
      L: width > breakpoints.M && width <= breakpoints.L,
      XL: width > breakpoints.L,
    },
    windowLessThan: {
      XS: false,
      S: width <= breakpoints.XS,
      M: width <= breakpoints.S,
      L: width <= breakpoints.M,
      XL: width <= breakpoints.L,
    },
    windowGreaterThan: {
      XS: width > breakpoints.XS,
      S: width > breakpoints.S,
      M: width > breakpoints.M,
      L: width > breakpoints.L,
      XL: false,
    },
  };
};

const useMediaQuery = (): ReturnType<typeof getStateFromWindowDimensions> => {
  const [windowDimensions, setWindowDimensions] = useState(
    getStateFromWindowDimensions
  );

  useEffect(() => {
    const updateDimensions = throttle(
      () => setWindowDimensions(getStateFromWindowDimensions),
      throttleLimit
    );

    window.addEventListener("resize", updateDimensions, false);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      updateDimensions.cancel();
    };
  }, []);

  return windowDimensions;
};

export type MediaQuery = ReturnType<typeof useMediaQuery>;
export { useMediaQuery };

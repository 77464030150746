// CONFIG
const FORM_ID = "e06bdaf7-9c61-4b35-b926-fbe8f146aae5";
const PORTAL_ID = 8805665;

const FORM_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;

// Main hook for interacting with the HubSpot form.
const useHubSpotForm = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const utmCampaign = urlParams.get("utm_campaign");
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmContent = urlParams.get("utm_content");

  const submit = ({
    onSuccess,
    contactInformation: { email, company, firstName, lastName },
  }: {
    onSuccess?: () => void;
    contactInformation: {
      firstName: string;
      lastName: string;
      email: string;
      company: string;
    };
  }) => {
    const formData = {
      fields: [
        // Contact information
        ...(utmCampaign
          ? [
              {
                objectTypeId: "0-1",
                name: "utm_campaign",
                value: utmCampaign,
              },
            ]
          : []),
        ...(utmSource
          ? [
              {
                objectTypeId: "0-1",
                name: "utm_source",
                value: utmSource,
              },
            ]
          : []),
        ...(utmMedium
          ? [
              {
                objectTypeId: "0-1",
                name: "utm_medium",
                value: utmMedium,
              },
            ]
          : []),
        ...(utmContent
          ? [
              {
                objectTypeId: "0-1",
                name: "utm_content",
                value: utmContent,
              },
            ]
          : []),
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "company",
          value: company,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstName,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastName,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    const formattedData = JSON.stringify(formData);

    fetch(FORM_URL, {
      method: "POST",
      body: formattedData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        // if any error occured, then catch it here
        console.error(err);
      });
  };

  return { submit };
};

export { useHubSpotForm };

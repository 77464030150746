/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Button } from "../components/Button";
import { Input } from "../components/input/Input";
import { PoweredByPrecisely } from "../components/PoweredByPrecisely";
import { Header1 } from "../components/text/Header1";
import { Paragraph } from "../components/text/Paragraph";
import { useMediaQuery } from "../hooks/useMediaQuery";
import data_illustration from "../assets/illustrations/data.svg";
import email_illustration from "../assets/illustrations/email.svg";
import { useTheme } from "@emotion/react";
import { useHubSpotForm } from "../hooks/useHubSpotForm";

const isEmailValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const PaywallField = ({ label, type, name, value, placeholder, onChange }) => {
  const { spacing, color } = useTheme();
  const { windowLessThan } = useMediaQuery();

  const isMobileLayout = windowLessThan.M;

  return (
    <label css={{ display: "block", marginBottom: "3rem" }}>
      <Paragraph
        css={{
          marginBottom: spacing.XS,
          fontFamily: "Helvetica Neue Pro",
          color: color.gray.dark,
        }}
      >
        {label}*
      </Paragraph>
      <Input
        placeholder={placeholder}
        css={{
          fontSize: "1.6rem",
          lineHeight: "2.4rem",
          padding: spacing.M,
          border: "1px solid rgb(232, 232, 232)",
          borderRadius: "0.6rem",
          width: isMobileLayout ? "100%" : "70%",
          maxWidth: "350px",
        }}
        name={name}
        value={value}
        type={type}
        onChange={({ target }) => onChange(target.value)}
      />
    </label>
  );
};

const LandingPage = () => {
  const { spacing, color } = useTheme();
  const { windowLessThan } = useMediaQuery();

  const isMobileLayout = windowLessThan.M;

  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

  const splitName = fullName.split(" ");
  const firstName = splitName[0];
  const lastName = splitName[splitName.length - 1];

  const isDisabled = !(
    firstName &&
    lastName &&
    isEmailValid(email) &&
    company &&
    isPrivacyPolicyChecked
  );

  const hubSpotForm = useHubSpotForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    hubSpotForm.submit({
      onSuccess: () => {
        setIsFormSubmitted(true);
      },
      contactInformation: { company, email, firstName, lastName },
    });
  };

  return (
    <div
      css={{
        overflow: "hidden",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: isMobileLayout ? "column" : "row",
      }}
    >
      {/* LEFT SECTION */}
      <div
        css={{
          boxSizing: "border-box",
          overflow: "hidden",
          background: color.primary.main,
          flex: 1,
          padding: isMobileLayout ? "4rem" : "10rem",
          position: "relative",
        }}
      >
        <div css={{ maxWidth: "600px" }}>
          <Header1
            css={{
              fontSize: "5.6rem",
              lineHeight: "6.2rem",
              marginBottom: "2rem",
              color: "white",
            }}
          >
            Get your legal KPIs, Objectives, and Key Results together
          </Header1>
          <Paragraph
            css={{
              fontSize: "2rem",
              lineHeight: "3rem",
              color: "rgba(255, 255, 255, 0.8)",
            }}
          >
            And keep track of your team's performance
          </Paragraph>
        </div>
        <img
          alt="Data driven decision illustration"
          css={{ width: isMobileLayout ? "100%" : "65%" }}
          src={data_illustration}
        />
      </div>

      {/* RIGHT SECTION */}
      <div
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: isMobileLayout ? "4rem" : "10rem 10rem 3rem 10rem",
        }}
      >
        {isFormSubmitted ? (
          <>
            <Header1 css={{ marginBottom: "2rem" }}>
              Thank you for submitting!
            </Header1>
            <Paragraph css={{ flexGrow: 1, marginBottom: "4rem" }}>
              You will hear from us shortly.
            </Paragraph>
            <img
              src={email_illustration}
              alt="email illustration"
              css={{
                width: "300px",
                height: "300px",
                margin: "auto",
                marginBottom: spacing.XL,
              }}
            />
          </>
        ) : (
          <>
            <Header1 css={{ marginBottom: "2rem" }}>
              Contact Information
            </Header1>
            <Paragraph css={{ marginBottom: "4rem" }}>
              Enter your contact information to receive our top KPIs and OKRs -
              ready to use for your legal team.
            </Paragraph>

            <form css={{ flexGrow: 1 }} onSubmit={handleSubmit}>
              <PaywallField
                name="name"
                type="input"
                label="Full name"
                placeholder="Full Name"
                value={fullName}
                onChange={setFullName}
              />
              <PaywallField
                placeholder="Company Name"
                name="company"
                type="input"
                label="Company name"
                value={company}
                onChange={setCompany}
              />
              <PaywallField
                placeholder="Email address"
                name="email"
                type="email"
                label="E-mail"
                value={email}
                onChange={setEmail}
              />
              <label
                css={{
                  margin: "2rem 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  checked={isPrivacyPolicyChecked}
                  onChange={() =>
                    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)
                  }
                  type="checkbox"
                  css={{ marginRight: "1rem" }}
                />
                <Paragraph css={{ color: color.gray.dark, lineHeight: 0 }}>
                  I agree to Precisely's{" "}
                  <a
                    css={{ color: "inherit", textDecoration: "underline" }}
                    target="blank"
                    href="https://preciselycontracts.com/privacy-and-cookies/"
                  >
                    Privacy Policy*
                  </a>
                </Paragraph>
              </label>
              <Button size="large" type="submit" isDisabled={isDisabled}>
                Sign up
              </Button>
            </form>
          </>
        )}

        <PoweredByPrecisely />
      </div>
    </div>
  );
};

export { LandingPage };

import styled from "@emotion/styled";

export const Input = styled.input(
  {
    border: "1px solid #8596A6",
    borderRadius: "3px",
    boxSizing: "border-box",
    fontSize: "14px",
  },
  ({ theme }) => ({
    padding: `${theme.spacing.S} ${theme.spacing.M}`,
  })
);

import styled from "@emotion/styled";

export const Header1 = styled.h1(
  {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "36px",
  },
  ({ theme: { color } }) => ({
    color: color.gray.dark,
  })
);

/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { ReactNode } from "react";

const Button = ({
  type,
  onClick,
  children,
  className,
  isDisabled = false,
  size = "medium",
  intent = "neutral",
}: ButtonProps) => {
  const { spacing, color } = useTheme();

  return (
    <button
      type={type}
      onClick={onClick}
      className={className}
      css={{
        marginTop: spacing.M,
        border: "1px solid transparent",
        transition: "all 0.3s",
        lineHeight: 0,
        padding:
          size === "large"
            ? "2.2rem 2.4rem 1.9rem 2.4rem"
            : `1.6rem 1.6rem 1.4rem 1.6rem`,
        fontSize: size === "large" ? "1.6rem" : "1.4rem",
        fontFamily: "Helvetica Neue Pro Roman",
        borderRadius: "0.6rem",
        color: "white",
        background: color.primary.main,

        "&:hover": isDisabled
          ? {}
          : {
              borderColor: color.primary.main,
              color: color.primary.main,
              background: "transparent",
            },

        opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

type ButtonProps = {
  className?: string;
  isDisabled?: boolean;
  type?: "button" | "submit" | undefined;
  children: ReactNode;
  onClick?: (event: any) => void;
  size?: "medium" | "large";
  intent?: "neutral" | "primary";
};

export { Button };

/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Paragraph } from "./text/Paragraph";
import logo from "../assets/img/logo_black.svg";

const PoweredByPrecisely = () => {
  const { spacing } = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5rem",
      }}
    >
      <a href="https://preciselycontracts.com/" target="blank">
        <img
          src={logo}
          alt="Fancy graphics"
          css={{
            opacity: 0.9,
            width: "100px",
            margin: `${spacing.S} 0`,
          }}
        />
      </a>
      <Paragraph css={{ textAlign: "center", fontSize: "1rem", opacity: 0.9 }}>
        © Precisely AB (556963-5286). Precisely Sweden: Kämpegatan 10, 411 04
        Gothenburg
      </Paragraph>
    </div>
  );
};

export { PoweredByPrecisely };
